<template>
	<div>
		<headerTop></headerTop>
		<headerMin></headerMin>
		<navlist :state="3"></navlist>
		<div class="content">
			<div class="crumbs"><span @click="$router.push('/')">首页</span>> <span @click="$router.push('healthy')">健康知识</span> >健康知识详情</div>
			<h2 class="title">{{title}}</h2>
			<div class="time">发布时间: {{infor.add_time|gettime}}   阅读量: {{infor.article_count}}</div>
			<div v-html="textinfor">
			</div>
		</div>
		<publicBottom></publicBottom>
	</div>
	
</template>

<script>
	import navlist from "@/components/home_repect/navlist.vue"
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
		import publicBottom from "@/components/public/public_bottom";
	export default{
		components:{
			navlist,
			headerTop,
			headerMin,
			publicBottom
		},
		name:"textinfor",
		created(){
			this.id=this.$route.query.id;
			this.gettextinfor();
		},
		data(){
			return{
				id:"",
				textinfor:"",
				title:"",
				infor:"",
			}
		},
		filters: {
			gettime(value) {
				let Y = "";
				let M = "";
				let D = "";
				let date = new Date(value * 1000);
				Y = date.getFullYear();
				M = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
				D = date.getDay() < 10 ? "0" + date.getDay() : date.getDay();
				return Y + "-" + M + "-" + D;
			}
		},
		methods:{
			gettextinfor(){
				this.$axios.post("/api/Article/getInfo",{id:this.id}).then(res=>{
					if(res.data.code==1){
						console.log(res);
						this.infor=res.data.data;
						this.title=res.data.data.title;
						this.textinfor=res.data.data.content;
					}
				});
			}
		}
	}
</script>

<style scoped lang="less">
	.content{
		width: 1200px;
		margin:0 auto;
		margin-bottom: 30px;
		.crumbs{
			font-size: 14px;
			margin-top: 20px;
		}
		.title{
			text-align: center;
			margin: 20px;
		}
		.time{
			margin: 20px 0;
			font-size: 14px;
			color:#555555;
			text-align: center;
			border-bottom: 2px solid #ccc;
			padding: 10px 0;
		}
	}
</style>
