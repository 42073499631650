<template>
	<div class="navs">
		<nav class="homenav">
			<ul>
				<li :class="{active:state==1}" @click="$router.push('/')"><span>首页</span></li>
				<li :class="{active:state==2}" @click="$router.push('/allGoods')"><span>全部产品</span></li>
				<li :class="{active:state==3}" @click="$router.push('/healthy')"><span>健康知识</span></li>
				<li :class="{active:state==4}" @click="$router.push('/promotion')"><span>活动促销</span></li>
				<li :class="{active:state==5}" @click="$router.push('/aboutUs')"><span>关于我们</span></li>
				<li :class="{active:state==6}"><span> <a :href="`tencent://message/?uin=${this.sysconfs.kefu_qq}&Menu=yes&Service=300&sigT=42a1e5347953b64c5ff3980f8a6e644d4b31456cb0b6ac6b27663a3c4dd0f4aa14a543b1716f9d45`">联系我们</a></span></li>
			</ul>
		</nav>
	</div>
</template>
<script>
	import {
		getSysconfs
	} from "@/request/public";
	export default {
		props: ['state'],
		data() {
			return {
				sysconfs: {}
			}
		},
		created() {
			// 获取网站基本信息
			getSysconfs({
				token: this.$token
			}).then(res => {
				if (res.code == 1) {
					this.sysconfs = res.data;
				}
			});
		}
	}
</script>

<style scoped lang="less">
	.homenav {
		width: 100%;
		min-width: 1200px;
		background-color: #38edc0;

		ul {
			height: 50px;
			width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;

			li {
				height: 50px;
				line-height: 50px;

				font-size: 20px;
				cursor: pointer;

				span {
					display: inline-block;
					border-bottom: 2px solid #fff;
					color: #fff;
					a {
						color: #fff !important;
					}
				}
			}

			.active {
				height: 48px;
				line-height: 48px;

				span {
					display: inline-block;
					border-bottom: 2px solid #fff;

				}
			}
		}
	}
</style>
